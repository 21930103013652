import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AdvertGoalModel } from '@/models/Adverts';
import { TikTokAdsFormModel } from '@/models/TikTok';

const SetYourGoal = ({
  goals,
  outputGoal,
  canStep,
}: {
  goals: AdvertGoalModel[];
  outputGoal: (value?: AdvertGoalModel) => void;
  canStep: (value: boolean) => void;
}) => {
  const { t } = useTranslation();

  const { setValue, watch } = useFormContext<TikTokAdsFormModel>();
  const formGoal = watch('ad.goal');

  useEffect(() => {
    canStep(!!formGoal);
  }, [canStep, formGoal]);

  return (
    <>
      <h3>{t('SOCIAL-ADS.SET-YOUR-GOAL')}</h3>
      <p className="text-faded mb48 mt16 mb20-lg-down">{t('SOCIAL-ADS.SET-YOUR-GOAL-DESCRIPTION')}</p>
      <div className="d-flex gap12 flex-wrap jc-center">
        {goals.map((goal, index) => (
          <div
            key={index}
            className={`card-inner flex-w30p flex-grow cursor-pointer min-w200 ${
              formGoal === goal?.value ? 'chosen-goal' : ''
            }`}
            onClick={() => {
              if (formGoal === goal?.value) {
                outputGoal(undefined);
                setValue('ad.goal', undefined);
              } else {
                outputGoal(goal);
                setValue('ad.goal', goal.value);
              }
            }}
          >
            <div className="mb16">
              <img className="goal-icon" src={`/images/logos/${goal.icon}-logo-white.svg`} alt="" />
            </div>
            <p className="fw-bold">{t(goal.title)}</p>
            <p className={`${formGoal === goal?.value ? '' : 'text-faded'} mt16 mb16`}>{t(goal.description)}</p>
          </div>
        ))}
      </div>
    </>
  );
};

export default SetYourGoal;
